import React from "react"
import App from "./App"


export default () => {

  return (
    <App />
  )
}
